import React from "react";
import useTranslations from "../useTranslations";
import { HiCheck } from "react-icons/hi";

const Hero = () => {
  const { pricingPayasyougo } = useTranslations();

  return (
    <div className="mx-auto pt-16 pb-2 px-4 sm:pt-12 pb-8 sm:px-6 lg:px-8">
      <div className="text-center">
        <h1 className="mt-1 text-2xl font-bold text-white sm:text-3xl sm:tracking-tight lg:text-4xl">
          {pricingPayasyougo.hero.title}
        </h1>
        <p className="max-w-xl mx-auto mt-5 text-xl text-gray-200">
          {pricingPayasyougo.hero.subtitle}
        </p>
      </div>
    </div>
  );
};

export default Hero;

import React, { useState } from "react";

import CtaSection from "../components/ctaSection";
import Hero from "../components/pricing/hero";
import Faq from "../components/pricing/faq";
import Simulator from "../components/pricing/simulator";
import useTranslations from "../components/useTranslations";
import HelmetHeader from "../components/helmetHeader";

const Pricing = ({ location }) => {
  const { pricingPayasyougo, lang } = useTranslations();
  const currencySelected = 'euros';

  return (
    <div className="bg-white">
      <HelmetHeader
        location={location}
        lang={lang}
        title={pricingPayasyougo.metas.title}
        metas={[{ name: "description", content: pricingPayasyougo.metas.description }]}
      />
      <div className="bg-gradient-to-r from-teal-500 to-cyan-600 -mb-44">
        <Hero />
        <Simulator currencySelected={currencySelected} />
      </div>
      <div className="pb-20 border-t-2 border-cyan-700 bg-gray-50" />
      <Faq />
      <CtaSection />
    </div>
  );
};

export default Pricing;

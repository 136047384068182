import React, { useState, useMemo, useEffect } from "react";
import { HiOutlinePresentationChartBar, HiTrash } from "react-icons/all";
import { HiCheck } from "react-icons/hi";
import useTranslations from "../useTranslations";
import reactStringReplace from "react-string-replace";

const currencies = {
  euros: "EUR",
};

const plans = [
  {
    requests: 2000000,
    monthlyPrice: {
      euros: 90,
      usDollars: 90,
    },
    additionnalRequestsPrice: {
      euros: 2,
      usDollars: 2,
    },
  },
  {
    requests: 10000000,
    monthlyPrice: {
      euros: 190,
      usDollars: 190,
    },
    additionnalRequestsPrice: {
      euros: 1.6,
      usDollars: 1.6,
    },
  },
  {
    requests: 25000000,
    monthlyPrice: {
      euros: 390,
      usDollars: 390,
    },
    additionnalRequestsPrice: {
      euros: 1.5,
      usDollars: 1.5,
    },
  }
];

const addons = [
  {
    name: "container",
    unitPrice: {
      euros: 30,
      usDollars: 30,
    },
  },
  {
    name: "domain",
    unitPrice: {
      euros: 10,
      usDollars: 10,
    },
  },
  {
    name: "zone",
    unitPrice: {
      euros: 20,
      usDollars: 20,
    },
  },
];

const Simulator = ({ currencySelected }) => {
  const { pricingSimulator } = useTranslations();
  const [calculatorRequestsValue, setCalculatorRequestsValue] = useState("");
  const [planSelected, setPlanSelected] = useState(null);
  const [addonsQuantities, setAddonsQuantities] = useState(
    addons.map((_, index) => ({ id: index, quantity: 0, selected: false }))
  );

  const languageCode = 'fr-FR';

  // Get the right plan depending on the calculator requests value
  useEffect(() => {
    if (!calculatorRequestsValue || calculatorRequestsValue === "") {
      return;
    }

    const sortedPricings = plans
      .map((plan, index) => {
        let additionalPrice = 0;
        if (plan.requests < calculatorRequestsValue) {
          additionalPrice =
            ((calculatorRequestsValue - plan.requests) / 100000) *
            plan.additionnalRequestsPrice[currencySelected];
        }

        return {
          value: plan.monthlyPrice[currencySelected] + additionalPrice,
          index,
        };
      })
      .sort((a, b) => a.value - b.value);

    setPlanSelected(sortedPricings[0].index);
  }, [calculatorRequestsValue, currencySelected]);

  const additionalRequests = useMemo(() => {
    if (planSelected === null || !calculatorRequestsValue) {
      return null;
    }

    const calc = calculatorRequestsValue - plans[planSelected].requests;

    if (calc <= 0) {
      return 0;
    }

    // Get only the additional requests consumed
    return Math.floor(calc / 100000) * 100000;
  }, [planSelected, calculatorRequestsValue]);

  const amountAdditionalRequests = useMemo(() => {
    if (additionalRequests <= 0) {
      return 0;
    }

    return (
      (additionalRequests / 100000) *
      plans[planSelected].additionnalRequestsPrice[currencySelected]
    );
  }, [additionalRequests, currencySelected, planSelected]);

  // Custom total amount depending on the plan + options
  const totalAmount = useMemo(() => {
    const addonsTotalAmount = addons.reduce((acc, addon, index) => {
      if (!addonsQuantities[index].selected) {
        return acc;
      }

      const quantity = addonsQuantities[index].quantity;

      if (quantity === 0) {
        return acc;
      }

      return (acc += quantity * addon.unitPrice[currencySelected]);
    }, 0);

    return (
      (planSelected !== null
        ? plans[planSelected].monthlyPrice[currencySelected]
        : 0) +
      amountAdditionalRequests +
      addonsTotalAmount
    );
  }, [
    planSelected,
    currencySelected,
    amountAdditionalRequests,
    addonsQuantities,
  ]);

  return (
    <div className="max-w-6xl mx-auto pb-16 px-4 sm:pb-24 sm:px-6 lg:px-16 z-5 relative">
      <div className="bg-white rounded-md shadow-md">
        <div className="flex flex-col lg:flex-row lg:space-x-6 p-6 lg:p-12">
          <div className="flex-1 flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="bg-gray-50 flex justify-between border border-b-0 border-gray-200 rounded-t-lg">
                  <span className="flex-1 text-right px-4 lg:px-6 py-3  text-xs font-bold text-gray-900">
                    {pricingSimulator.requestsPerMonth}
                  </span>

                  <span className="flex-1 text-right px-4 lg:px-6 py-3 text-xs font-bold text-gray-900">
                    {pricingSimulator.monthlyPrice}
                  </span>
                  <span className="flex-1 text-right px-4 lg:px-6 py-3 text-xs font-bold text-gray-900">
                    {pricingSimulator.additionalRequests}
                  </span>
                </div>
                <div className="bg-gray-200 flex flex-col space-y-px border border-gray-200 rounded-b-md">
                  {plans.map((plan, index) => {
                    const isSelected = planSelected === index;

                    return (
                      <div
                        key={plan.requests}
                        className={`${
                          isSelected
                            ? "bg-green-100 border-green-600"
                            : "border-white bg-white hover:bg-gray-50 hover:border-gray-50"
                        } flex border-2 last:rounded-b-md cursor-pointer`}
                        onClick={() => {
                          setPlanSelected(index);
                          setCalculatorRequestsValue(plan.requests);
                        }}
                      >
                        <span className="flex-1 lg:text-base text-right font-bold pl-4 lg:px-6 py-4 whitespace-nowrap text-gray-900">
                          {new Intl.NumberFormat(languageCode, {
                            notation: "standard",
                          }).format(plan.requests)}
                        </span>

                        <span className="flex-1 lg:text-base text-right px-4 lg:px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {new Intl.NumberFormat(languageCode, {
                            style: "currency",
                            currency: currencies[currencySelected],
                            maximumFractionDigits: 0,
                          }).format(plan.monthlyPrice[currencySelected])}
                        </span>
                        <span className="flex-1 lg:text-base text-right px-4 lg:px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          +{" "}
                          {new Intl.NumberFormat(languageCode, {
                            style: "currency",
                            currency: currencies[currencySelected],
                          }).format(
                            plan.additionnalRequestsPrice[currencySelected]
                          )}{" "}
                          <span className="text-xs block lg:inline text-gray-500">
                            {pricingSimulator.per100k}
                          </span>
                        </span>
                      </div>
                    );
                  })}
                </div>
                <div className="px-6 pb-3 pt-5 relative -mt-2 border border-t-0 border-dashed border-gray-200 rounded-b-lg">
                  <div className="flex items-center space-x-5">
                    <HiOutlinePresentationChartBar className="text-gray-400 h-8 w-8" />
                    <div className="flex flex-col">
                      <p className="text-sm font-bold text-gray-900">
                        {pricingSimulator.biggerPlan}
                      </p>
                      <p className="text-sm text-gray-700">
                        {reactStringReplace(
                          pricingSimulator.highVolume,
                          "{{emailUs}}",
                          () => (
                            <a
                              className="text-cyan-600 underline"
                              href="mailto:contact@addingwell.com"
                            >
                              {pricingSimulator.emailUs}
                            </a>
                          )
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="relative mt-8 mb-8">
              <div
                className="absolute inset-0 flex items-center"
                aria-hidden="true"
              >
                <div className="w-full border-t border-gray-200" />
              </div>
              <div className="relative flex justify-center">
                <span className="px-3 py-1 bg-gray-50 border border-gray-200 rounded-full text-sm font-medium text-gray-500">
                  {pricingSimulator.addOns}
                </span>
              </div>
            </div>

            <div className="py-2 min-w-full">
              <span className="text-xl font-bold text-gray-900 mb-1">
                {pricingSimulator.addOns}
              </span>
              <p className="text-sm font-base text-gray-700">
                {pricingSimulator.addOnsDescription}
              </p>
              <div className="bg-white my-4 flex flex-col space-y-3">
                {addons.map((addon, index) => {
                  const isSelected = addonsQuantities[index].selected;
                  const [isHover, setIsHover] = useState(false);

                  return (
                    <button
                      key={addon.name}
                      className={`${
                        isSelected
                          ? "border-2 border-green-600 bg-green-100 hover:border-red-500 hover:bg-red-50"
                          : "border border-gray-200 hover:bg-gray-50"
                      } flex flex-col sm:flex-row sm:items-center sm:justify-between rounded-md shadow-sm py-4`}
                      onClick={() => {
                        const updatedValues = [...addonsQuantities];
                        updatedValues[index].selected =
                          !updatedValues[index].selected;

                        if (updatedValues[index].quantity === 0) {
                          updatedValues[index].quantity = 1;
                        }

                        if (!planSelected && plans?.length > 0) {
                          setPlanSelected(0);
                          setCalculatorRequestsValue(plans[0].requests);
                        }

                        setAddonsQuantities(updatedValues);
                      }}
                      onMouseEnter={() => setIsHover(true)}
                      onMouseLeave={() => setIsHover(false)}
                    >
                      <p className="px-6 whitespace-nowrap font-bold text-gray-900">
                        {isHover && isSelected && (
                          <span className="text-red-500">
                            {pricingSimulator.remove}
                          </span>
                        )}
                        {pricingSimulator[addon.name]}{" "}
                        {isSelected && !isHover && (
                          <span className="text-green-600">
                            {pricingSimulator.added}
                          </span>
                        )}
                      </p>

                      <p className="px-6 whitespace-nowrap text-right text-sm text-gray-900">
                        {!isSelected && <span>+ </span>}
                        {isSelected && isHover && <span>- </span>}
                        {new Intl.NumberFormat(languageCode, {
                          style: "currency",
                          currency: currencies[currencySelected],
                          maximumFractionDigits: 0,
                        }).format(addon.unitPrice[currencySelected])}{" "}
                        {pricingSimulator.perMonth}
                      </p>
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
          <div>
            <div className="lg:sticky lg:top-28 w-full lg:w-96">
              <div className="mt-10 lg:mt-0 bg-white border border-gray-200 rounded-lg">
                <div className="bg-gray-50 border-b rounded-t-md border-gray-200">
                  <div className="flex p-4">
                    <div className="flex-1  relative">
                      <input
                        min="0"
                        type="number"
                        name="price"
                        id="price"
                        className="focus:ring-cyan-600 focus:border-cyan-600 block w-full pl-3 pr-8 py-3 text-sm border border-gray-300 rounded-md"
                        placeholder="0"
                        aria-describedby="price-currency"
                        value={calculatorRequestsValue}
                        onChange={(event) =>
                          setCalculatorRequestsValue(event.target.value)
                        }
                      />
                      <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                        <span
                          className="text-gray-500 text-sm"
                          id="price-currency"
                        >
                          {pricingSimulator.estimatedRequets}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-6">
                  {planSelected === null && (
                    <div className="flex flex-col items-center mt-5">
                      <span className="px-10 text-md text-gray-900 font-bold">
                        {pricingSimulator.bestPlanTitle}
                      </span>
                      <span className="px-10 text-sm text-gray-400 font-normal text-center mt-3 mb-5">
                        {pricingSimulator.bestPlanDescription}
                      </span>
                    </div>
                  )}
                  {totalAmount !== 0 && (
                    <>
                      <div className="mt-4 mb-10">
                        <p className="text-5xl text-gray-900 font-semibold text-center">
                          {new Intl.NumberFormat(languageCode, {
                            style: "currency",
                            currency: currencies[currencySelected],
                          }).format(totalAmount)}
                        </p>
                        <span className="text-center mt-2 text-md block text-gray-400">
                          {pricingSimulator.estimatedCost}
                        </span>
                      </div>
                    </>
                  )}
                  {planSelected !== null && (
                    <div className="border-t border-b border-gray-100 py-2 mb-5">
                      <div className="flex justify-between">
                        <p className="text-gray-500 text-sm">
                          {new Intl.NumberFormat(languageCode, {
                            notation: "standard",
                          }).format(plans[planSelected].requests)}{" "}
                          {pricingSimulator.requestsPlan}
                        </p>
                        <p className="text-gray-500 text-sm">
                          {new Intl.NumberFormat(languageCode, {
                            style: "currency",
                            currency: currencies[currencySelected],
                            maximumFractionDigits: 0,
                          }).format(
                            plans[planSelected].monthlyPrice[currencySelected]
                          )}
                        </p>
                      </div>

                      {additionalRequests !== 0 && (
                        <>
                          <div className="relative mt-1 mb-1">
                            <div
                              className="absolute inset-0 flex items-center"
                              aria-hidden="true"
                            >
                              <div className="w-full border-t border-gray-100" />
                            </div>
                            <div className="relative flex justify-center">
                              <span className="bg-white px-2 text-gray-400">
                                <svg
                                  className="h-3 w-3"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 20 20"
                                >
                                  <path
                                    fill="currentColor"
                                    fillRule="evenodd"
                                    d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </span>
                            </div>
                          </div>

                          <div className="flex justify-between">
                            <p className="text-gray-500 text-sm">
                              {new Intl.NumberFormat(languageCode, {
                                notation: "standard",
                              }).format(additionalRequests)}{" "}
                              {pricingSimulator.additionalRequests.toLowerCase()}
                            </p>
                            <p className="text-gray-500 text-sm">
                              {new Intl.NumberFormat(languageCode, {
                                style: "currency",
                                currency: currencies[currencySelected],
                              }).format(amountAdditionalRequests)}
                            </p>
                          </div>
                        </>
                      )}
                      {addonsQuantities
                        .filter((addonQuantity) => addonQuantity.selected)
                        .map((addonQuantity) => {
                          const addonData = addons[addonQuantity.id];
                          return (
                            <div key={addonQuantity.id}>
                              <div className="relative mt-1 mb-1">
                                <div
                                  className="absolute inset-0 flex items-center"
                                  aria-hidden="true"
                                >
                                  <div className="w-full border-t border-gray-100" />
                                </div>
                                <div className="relative flex justify-center">
                                  <span className="bg-white px-2 text-gray-400">
                                    <svg
                                      className="h-3 w-3"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 20 20"
                                    >
                                      <path
                                        fill="currentColor"
                                        fillRule="evenodd"
                                        d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  </span>
                                </div>
                              </div>
                              <div
                                key={addonQuantity.index}
                                className="flex justify-between items-center"
                              >
                                <span className="text-gray-500 text-sm">
                                  {reactStringReplace(
                                    pricingSimulator.additional,
                                    "{{addOnName}}",
                                    () => pricingSimulator[addonData.name]
                                  )}{" "}
                                  x{addonQuantity.quantity}
                                </span>

                                <div className="flex items-center">
                                  <div className="flex items-center h-5 gap-2">
                                    <button
                                      data-action="decrement"
                                      className="flex items-center justify-center bg-gray-50 text-gray-900 hover:bg-gray-100 border border-gray-200 h-5 w-5 rounded-full cursor-pointer outline-none"
                                      onClick={() => {
                                        const updatedValues = [
                                          ...addonsQuantities,
                                        ];

                                        if (addonQuantity.quantity === 1) {
                                          updatedValues[
                                            addonQuantity.id
                                          ].selected = false;

                                          setAddonsQuantities(updatedValues);
                                          return;
                                        }

                                        updatedValues[
                                          addonQuantity.id
                                        ].quantity = parseInt(
                                          updatedValues[addonQuantity.id]
                                            .quantity - 1,
                                          10
                                        );

                                        setAddonsQuantities(updatedValues);
                                      }}
                                    >
                                      <span className="text-xs font-normal">
                                        −
                                      </span>
                                    </button>
                                    <button
                                      data-action="increment"
                                      className="flex items-center justify-center bg-gray-50 text-gray-900 hover:bg-gray-100 border border-gray-200 h-5 w-5 rounded-full cursor-pointer outline-none"
                                      onClick={() => {
                                        const updatedValues = [
                                          ...addonsQuantities,
                                        ];
                                        updatedValues[
                                          addonQuantity.id
                                        ].quantity = parseInt(
                                          updatedValues[addonQuantity.id]
                                            .quantity + 1,
                                          10
                                        );

                                        setAddonsQuantities(updatedValues);
                                      }}
                                    >
                                      <span className="text-xs font-normal">
                                        +
                                      </span>
                                    </button>
                                  </div>
                                  <p className="text-gray-500 text-right text-sm w-16">
                                    {new Intl.NumberFormat(languageCode, {
                                      style: "currency",
                                      currency: currencies[currencySelected],
                                      maximumFractionDigits: 0,
                                    }).format(
                                      addonQuantity.quantity *
                                        addonData.unitPrice[currencySelected]
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  )}

                  {totalAmount !== 0 && (
                    <>
                      <a
                        href={`${process.env.GATSBY_APP_URL}/signup`}
                        target="_blank"
                        rel="noopener"
                        className="mt-4 w-full whitespace-nowrap inline-flex items-center justify-center px-4 py-3 border border-transparent rounded-md shadow-sm text-lg text-white bg-cyan-600 hover:bg-cyan-700"
                      >
                        {pricingSimulator.freeTrial}
                      </a>
                      <button
                        className="block text-gray-400 m-auto pt-4 text-xs underline hover:no-underline"
                        onClick={() => {
                          setPlanSelected(null);
                          setAddonsQuantities(
                            addonsQuantities.map((addon) => ({
                              ...addon,
                              selected: false,
                              quantity: 0,
                            }))
                          );
                          setCalculatorRequestsValue("");
                        }}
                      >
                        {pricingSimulator.resetSimulator}
                      </button>
                    </>
                  )}
                </div>
              </div>
              <p className="text-gray-400 text-xs text-center p-4">
                {pricingSimulator.addOnsConditions}
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col bg-green-100 border-t-2 border-green-600 pb-10 rounded-b-md">
          <div className="relative -mt-4 px-5 py-1 flex items-center mx-auto bg-green-600 rounded-full">
            <HiCheck className="h-6 w-6 mr-2 text-white" />
            <span className="text-white font-bold">
              {pricingSimulator.featuresIncluded.title}
            </span>
          </div>
          <div className="flex flex-col md:flex-row px-8 md:px-16 lg:px-32 my-10">
            <div className="flex-1">
              <div className="flex items-center">
                <div className="bg-green-600 rounded-full p-1">
                  <HiCheck className="text-white h-3 w-3" />
                </div>
                <span className="ml-2 text-md text-gray-900 font-bold">
                  {pricingSimulator.featuresIncluded.leftTitle}
                </span>
              </div>
              <p className="ml-7 mt-1 text-sm text-gray-700 pr-5">
                {pricingSimulator.featuresIncluded.leftDescription}
              </p>
            </div>
            <div className="flex-1 mt-8 md:mt-0">
              <div className="flex items-center">
                <div className="bg-green-600 rounded-full p-1">
                  <HiCheck className="text-white h-3 w-3" />
                </div>
                <span className="ml-2 text-md text-gray-900 font-bold">
                  {pricingSimulator.featuresIncluded.rightTitle}
                </span>
              </div>
              <p className="ml-7 mt-1 text-sm text-gray-700 pr-5">
                {pricingSimulator.featuresIncluded.rightDescription}
              </p>
            </div>
          </div>
          <div className="flex justify-center">
            <div>
              <a
                href={`${process.env.GATSBY_APP_URL}/signup`}
                target="_blank"
                rel="noopener"
                className="flex flex-col mt-4 w-full whitespace-nowrap items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm bg-cyan-600 hover:bg-cyan-700"
              >
                <span className="text-lg text-white">
                  {pricingSimulator.freeTrial}
                </span>
                <span className="mt-1 text-xs text-cyan-100">
                  {pricingSimulator.noCreditCard}
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Simulator;
